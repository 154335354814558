//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Footer',
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      phone: '',
      email: '',
      fax: '',
      address: '',
      website: '',
      openHours: '',
      quickLinks: [],
      socialBadges: [],
      footerLogo: '',
      quickLinksTitle: '',
      contactUsTitle: '',
      allRightsReserved: '',
      allRightsReservedLinkPart: '',
    }
  },
  computed: {
    websiteUrlWithHttps() {
      if (!this.website) return ''
      if (this.website.startsWith('http://') || this.website.startsWith('https://')) {
        return this.website
      } else {
        return 'https://' + this.website
      }
    },
    allRightsReservedYear() {
      return new Date().getFullYear();
    },
  },
  async mounted() {
    await this.getFooterData()
  },
  methods: {
    async getFooterData() {
      const footerDataResult = (await this.$axios.get(`/contents/footer`))
      if(footerDataResult.status !== 200) {
        this.$toast.error('Something went wrong')
        return
      }
      const [footerData] = footerDataResult.data &&  footerDataResult.data.details ?  footerDataResult.data.details.sections : null

      if (footerData) {
        this.quickLinksTitle =
            footerData.quick_links_title?.fieldValue?.value
        this.contactUsTitle =
            footerData.contact_us_title?.fieldValue?.value
        this.phone = footerData.phone?.fieldValue?.value
        this.email = footerData.email?.fieldValue?.value
        this.fax = footerData.fax?.fieldValue?.value
        this.address = footerData.address?.fieldValue?.value
        this.website = footerData.website?.fieldValue?.value
        this.openHours = footerData.open_hours?.fieldValue?.value
        this.quickLinks = this.getItems(footerData.quick_links)
        this.socialBadges = this.getItems(
            footerData.social_badges
        )
        this.footerLogo =
            footerData.footer_logo?.fieldValue?.fullFileUrl
        this.allRightsReserved =
            footerData.all_rights_reserved?.fieldValue?.value
        this.allRightsReservedLinkPart =
            footerData.all_rights_reserved_link_part?.fieldValue?.value
      }
    },
    getItems(items) {
      const vueObj = {}
      Object.assign(vueObj, items)
      return vueObj
    },
  },
}
